import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import './App.css';  // Certifique-se de que o arquivo App.css está presente

function HomePage() {
  // Estado para controlar a visibilidade do botão
  const [isVisible, setIsVisible] = useState(false);

  // Função para detectar o scroll e mostrar/esconder o botão
  const handleScroll = () => {
    if (window.scrollY > 300) {  // Mostra o botão após o usuário rolar 300px
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Adiciona o listener de scroll quando o componente for montado
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    
    // Remove o listener de scroll quando o componente for desmontado
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Função para rolar a página até o topo
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="d-flex flex-column justify-content-between">
      {/* Meta tags para SEO e responsividade */}
      <head>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <title>Compsoft - Sistemas</title>
      </head>

      {/* Primeira seção ocupando toda a altura da tela */}
      <section className="d-flex flex-column justify-content-between min-vh-100">
        {/* Logo na parte superior */}
        <header className="text-center my-4">
          <img src="menu.png" alt="Logo" className="img-fluid" style={{ maxWidth: '1400px' }} />
        </header>

        {/* Imagens alinhadas lado a lado */}
        <div className="container mb-4">
          <div className="row menu">
            <div className="col-md-4">
              <a href="#section1"><img src="csfood.png" alt="Image 1" className="img-fluid same-size img-hover-effect" /></a>
            </div>
            <div className="col-md-4">
              <a href="#section2"><img src="cspdv.png" alt="Image 2" className="img-fluid same-size img-hover-effect" /></a>
            </div>
            <div className="col-md-4">
              <a href="#section3"><img src="csnfe.png" alt="Image 3" className="img-fluid same-size img-hover-effect" /></a>
            </div>
          </div>
        </div>

        {/* Link centralizado */}
        <main className="d-flex flex-column align-items-center justify-content-center flex-grow-1">
          <h2 className="mb-4">Contadores</h2>
          <a href="https://portal.compsoft.com.br" className="btn btn-primary btn-lg">
            <FontAwesomeIcon icon={faArrowUp} className="mr-2" /> Acesse seus XMLs clicando aqui
          </a>
        </main>

        {/* Informação na parte inferior */}
        <footer className="text-center mt-4 mb-2">
          
        </footer>
      </section>

      {/* Seções clicáveis */}
      <div id="section1" className="p-4 my-4 bg-light border">
      {/* Anúncio do CSFood */}
      <section className="container my-5">
        <div className="text-center">
          <h1>CSFood: A Solução Completa para Bares, Restaurantes e Delivery!</h1>
          <p className="lead">
            Apresentamos o <strong>CSFood</strong>, a ferramenta definitiva para gerenciar seu estabelecimento gastronômico com eficiência e simplicidade. Seja você dono de um bar, restaurante ou serviço de delivery, o CSFood está aqui para transformar a forma como você opera, oferecendo funcionalidades que atendem a todas as suas necessidades:
          </p>
        </div>

        <div className="row mt-4">
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title">Controle de Comandas</h3>
                <p className="card-text">
                  Gerencie as comandas dos seus clientes com facilidade, reduzindo erros e otimizando o atendimento.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title">PDV Integrado</h3>
                <p className="card-text">
                  Modernize seu ponto de venda com nosso sistema PDV, que oferece uma interface intuitiva e rápida.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div class="card-body">
                <h3 class="card-title">Integração com Vendas Online</h3>
                <p class="card-text">
                  Aumente suas vendas conectando-se facilmente a plataformas de delivery online.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-md-4">
            <div class="card">
              <div class="card-body">
                <h3 class="card-title">Impressão de Pedidos na Cozinha</h3>
                <p class="card-text">
                  Envie os pedidos diretamente para a cozinha, garantindo que cada prato seja preparado de forma precisa e rápida.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card">
              <div class="card-body">
                <h3 class="card-title">Eficiência Operacional</h3>
                <p class="card-text">
                  Automatize processos e reduza o tempo de espera dos clientes.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card">
              <div class="card-body">
                <h3 class="card-title">Controle Total</h3>
                <p class="card-text">
                  Tenha acesso a relatórios detalhados e controle financeiro, ajudando na tomada de decisões estratégicas.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="text-center mt-4">
          <p class="lead">
            Transforme seu estabelecimento com o <strong>CSFood</strong> e descubra a diferença que uma gestão eficiente pode fazer. Solicite uma demonstração gratuita hoje mesmo!
          </p>
        </div>
      </section>

      </div>
      <div id="section2" className="p-4 my-4 bg-light border">
      {/* Anúncio do CSPDV */}
      <section className="container my-5">
        <div className="text-center">
          <h1>CSPDV: O Sistema Completo para Gestão de Vendas e Estoque!</h1>
          <p className="lead">
            Apresentamos o <strong>CSPDV</strong>, a solução ideal para gerenciar a operação do seu estabelecimento comercial. Com funcionalidades avançadas, o CSPDV oferece tudo o que você precisa para controlar seu estoque, gerenciar o financeiro, emitir documentos fiscais e muito mais. Descubra todas as vantagens deste sistema inovador:
          </p>
        </div>

        <div className="row mt-4">
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title">Controle Financeiro</h3>
                <p className="card-text">
                  Controle de receitas e despesas de maneira simples e eficiente, com relatórios detalhados para otimizar a gestão financeira.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title">Controle de Estoque</h3>
                <p className="card-text">
                  Monitore o estoque em tempo real, com facilidades como entradas automáticas a partir de XMLs, evitando erros e otimizando a operação.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title">Emissão de Vendas</h3>
                <p className="card-text">
                  Emita vendas de forma rápida e precisa, com integração total ao sistema de gestão e emissão de documentos fiscais.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title">Emissão de Documentos Fiscais</h3>
                <p className="card-text">
                  Gere facilmente documentos fiscais como SAT e NFce, garantindo conformidade fiscal e simplificando processos burocráticos.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title">Facilidade na Entrada de Itens</h3>
                <p className="card-text">
                  Realize entradas de produtos e itens de forma rápida, utilizando XMLs para importar dados automaticamente, sem erros manuais.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title">Gestão Completa</h3>
                <p className="card-text">
                  Tenha uma visão 360° do seu negócio, com relatórios detalhados e controle total sobre vendas, estoque e finanças.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="text-center mt-4">
          <p className="lead">
            Transforme a gestão do seu estabelecimento com o <strong>CSPDV</strong> e descubra como a tecnologia pode otimizar sua operação. Solicite uma demonstração gratuita hoje mesmo!
          </p>
        </div>
      </section>
      </div>
      <div id="section3" className="p-4 my-4 bg-light border">
              {/* Anúncio do CSNFe */}
      <section className="container my-5">
        <div className="text-center">
          <h1>CSNFe: A Solução Completa para Emissão de Notas Fiscais!</h1>
          <p className="lead">
            Apresentamos o <strong>CSNFe</strong>, a solução ideal para emitir e gerenciar suas Notas Fiscais de forma simples e eficiente. Com o CSNFe, você tem tudo o que precisa para realizar a emissão de NF-e, SAT e NFC-e com total facilidade, integração com outras soluções da CompSoft, e ainda pode gerenciar e compartilhar seus XMLs com a contabilidade. Veja as funcionalidades principais do sistema:
          </p>
        </div>

        <div className="row mt-4">
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title">Emissão de Notas Fiscais</h3>
                <p className="card-text">
                  Emita NF-e de forma simples e rápida, com todos os dados preenchidos de acordo com um cadastro pré-definido.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title">Integração com Outras Soluções</h3>
                <p className="card-text">
                  O CSNFe pode ser integrado facilmente com outras soluções da CompSoft, como o CSPDV e CSFood, facilitando a gestão de todos os processos do seu negócio.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title">Compartilhamento de XML com Contabilidade</h3>
                <p className="card-text">
                  Envie os XMLs das notas diretamente para sua contabilidade, garantindo que todos os documentos fiscais estejam em conformidade.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title">Facilidade no Gerenciamento de Notas</h3>
                <p className="card-text">
                  Tenha um painel de controle intuitivo para gerenciar e consultar todas as suas notas emitidas, com filtros e relatórios avançados.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title">Segurança e Conformidade Fiscal</h3>
                <p className="card-text">
                  Com o CSNFe, você tem total segurança na emissão das suas notas, com conformidade fiscal garantida para evitar problemas com a Receita Federal.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title">Armazenamento Seguro de XML</h3>
                <p className="card-text">
                  O sistema realiza o armazenamento seguro dos XMLs, permitindo que você acesse e compartilhe os arquivos quando necessário.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="text-center mt-4">
          <p className="lead">
            Facilite a emissão e gestão de suas Notas Fiscais com o <strong>CSNFe</strong> e tenha total controle sobre seus documentos fiscais. Solicite uma demonstração gratuita hoje mesmo!
          </p>
        </div>
      </section>


      </div>

      {/* Botão flutuante para voltar ao topo */}
      {isVisible && (
        <button
          className="btn btn-primary btn-lg scroll-to-top"
          onClick={scrollToTop}
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            zIndex: '1000',
            borderRadius: '50%',
            width: '60px',
            height: '60px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px',
          }}
        >
          <FontAwesomeIcon icon={faArrowUp} />
        </button>
      )}
    </div>
  );
}

export default HomePage;
